<template>
    <div>
        <v-toolbar flat>
            <v-icon left>mdi-target</v-icon>
            <v-toolbar-title>PRODUCTOS OBJETIVO</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn @click="abrirProductoDialog(null)" color="blue" small dark><v-icon>mdi-plus</v-icon> Crear</v-btn>
            <v-text-field class="mx-4 pt-4 mt-4" v-model="search" label="Buscar" ></v-text-field>
            <!-- <v-divider class="mx-4" vertical></v-divider> -->
            <!-- <v-btn @click="borrarProducto()" color="blue" small dark><v-icon>mdi-delete</v-icon>Borrar</v-btn> -->
        </v-toolbar>
        <v-data-table :headers="headers" :items-per-page="itemsPerPage" 
            :items="productos" class="elevation-1" small
            item-key="id" hide-default-footer
            :search="search"
            >
            <template v-slot:item.opciones="{ item }">
                <v-btn small text @click="borrarProducto(item.id)" color="blue" small dark><v-icon small
                        color="red">mdi-delete</v-icon></v-btn>
            </template>
            <template v-slot:item.id="{ item }">
                <v-btn text small @click="abrirProductoDialog(item.id)">
                    {{ item.id }}
                </v-btn>
            </template>
            <template v-slot:footer>
                <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
                    total-visible="10"></v-pagination>
            </template>
        </v-data-table>

        <!-- DIALOGO FUENTE -->
        <v-dialog v-model="dialogoProducto" persistent width="700">
            <v-form ref="formProducto">
                <v-card>

                    <v-toolbar dark color="blue" height="40" flat>
                        <v-icon left>mdi-target</v-icon>
                        Producto Objetivo
                        <v-spacer></v-spacer>
                        <v-btn small text @click="dialogoProducto = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-row class="mt-1 mx-2 pt-0 mt-0">
                        <v-col md="12" class="py-0 my-0">
                            <v-text-field v-if="crearProducto == false"  :rules="requiredRule" v-model="datosProducto.id"
                                label="Nombre"></v-text-field>
                        </v-col>

                        <v-col md="12" class="py-0 my-0">
                            <!-- <v-text-field :rules="requiredRule" v-model="datosProducto.internal_name"
                                label="Descripción"></v-text-field> -->
                           
                            <v-autocomplete
                                :rules="requiredRule"
                                :items="catalogo"
                                item-value="product_id"
                                item-text="internal_name"
                                label="Producto"
                                v-model="datosProducto.product_id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-text-field :rules="requiredRule" v-model="datosProducto.prioridad" label="Prioridad"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-toolbar>
                        <v-btn color="primary" small @click="guardarProducto">
                            <v-icon>mdi-content-save</v-icon>Guardar
                        </v-btn>
                        <v-divider vertical class="px-2" inset></v-divider>
                        <v-btn text small @click="dialogoProducto = false">
                            <v-icon>mdi-content-cancel</v-icon>Cancelar
                        </v-btn>
                    </v-toolbar>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from 'vue';

export default {
    name: "ProductosObjetivo",
    data: () => ({
        show: false,

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        search: "",
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 0,
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "id" },
            { text: "Codigo producto", align: "start", value: "product_id" },
            { text: "Nombre", align: "start", value: "internal_name" },
            { text: "Prioridad", align: "start", value: "prioridad" },
        ],
        productos: [],
        
        dialogoProducto: false,
        datosProducto: {
            id: "",
            product_id: "",
            prioridad: "",
            descripcion: ""
        },
        catalogo: [],
        crearProducto: false,
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
            "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),

        cargarDatos() {
            this.setLoadingTable(true);

            this.setUrl("productos-objetivo");

            this.requestApi({
                method: "GET",
                data: {
                    page: this.currentPage,
                    page_size: this.itemsPerPage,
                    //paginator: true,
                    //source_type_id: "LEAD_SOURCE",
                },
            })
                .then((res) => {
                    //console.log(res.data);
                    this.productos = res.data._embedded.productos_objetivo;
                    this.pageCount = res.data.page_count;
                    this.currentPage = res.data.page;
                    this.totalItems = res.data.total_items;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },
        abrirProductoDialog(Id) {
            this.dialogoProducto  = true;
            this.cargarDatosProducto(Id);
            this.datosProducto = {
                id: "",
                product_id: "",
                description: "",
            };
            this.crearProducto = true;
            if (Id != null) {
                this.crearProducto = false;                                
            }
        },

        cargarDatosProducto(id) {
            this.setLoadingTable(true);
            this.setUrl("productos-objetivo/" + id);
            this.requestApi({
                method: "GET",
                data: {},
            })
                .then((res) => {
                    console.log(res.data.detail.producto_objetivo)
                    if(res.data.detail.producto_objetivo)
                    this.datosProducto = res.data.detail.producto_objetivo;
                    this.catalogo = res.data.detail.catalogo
                })
                .then(() => {
                    this.setLoadingTable(false);
                });
        },

        guardarProducto() {
            if (!this.$refs.formProducto.validate()) {
                return false
            }
            this.setLoadingTable(true);
            this.setUrl("productos-objetivo");
            this.requestApi({
                method: "POST",
                data: {
                    accion: this.crearProducto == true ? "crearProducto" : "modificarProducto",
                    datosProducto: this.datosProducto,
                },
            }).then((res) => {
                    //console.log(res.data);
                    this.cargarDatos();
                    this.dialogoProducto = false;
                })
                .then(() => {
                    this.setLoadingTable(false);
                });

        },
        borrarProducto(Id) {

            Vue.swal({
                html: "Está seguro de eliminar este producto  ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                this.setLoadingTable(true);
                this.setUrl("productos-objetivo");
                this.requestApi({
                    method: "POST",
                    data: {
                        accion: "borrarProducto",
                        id: Id, 
                    },
                })
                    .then((res) => {
                        //console.log(res.data);
                        this.cargarDatos();
                        this.dialogoProducto = false;
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
            });
        },
        handlePageChange(value) {
            this.cargarDatos();
        },
    },
    mounted() {
        this.cargarDatos();
        this.setTitleToolbar("PRODUCTOS OBJETIVO");
    },
};
</script>
